@tailwind base;
@tailwind components;
@tailwind utilities;

.crowlyn-divider{
    clip-path: polygon(50% 23%, 100% 50%, 51% 73%, 0% 50%);
}
.crowlyn-divider-up{
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 47%, 0 100%, 0 1%);
}
.crowlyn-divider-down{
    clip-path: polygon(50% 47%, 100% 0, 100% 100%, 50% 100%, 0 100%, 0% 1%);
}